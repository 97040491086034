<template>
  <div>
    <div class="outer-wrapper">
      <div class="m-option-wrapper">
        <option-panel :askData="askData" @searchGo="searchGo"></option-panel>
      </div>
      <div class="table-wrapper" id="data-list">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label" class="pane_label">
              <Icon type="ios-card" />数据表
            </span>
            <list-with-page :tableParams="tableParams" @handlePage="handlePage"></list-with-page>
          </el-tab-pane>
          <slot
            :choosedOptions="choosedOptions"
          ></slot>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import optionPanel from "./OptionPanel.vue";
import listWithPage from "./ListWithPage.vue";

let userToken = localStorage.getItem("current_user_token");

export default {
  name: "dataView",
  components: {
    optionPanel,
    listWithPage
  },
  props: {
    columnsFormat: {
      type: Array,
      required: true,
      default(){
        return [];
      }
    },
    askData: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      tableParams: {
        Tpage: {
          currentPage: 1,
          currentSize: 20
        },
        totalItems: 0,
        noDataText: "",
        loading: true,
        columnsFormat: this.columnsFormat,
        contentData: [],
        tableWidth: 1224,
        pageSizeOptions: [20, 50, 100, 200, 400]
      },
      choosedOptions: {
        selectedList: [],
        keyword: ""
      },
      selected: this.selectedRow,
    };
  },
  methods: {
    get_data() {
      this.tableParams.loading = true;
      this.tableParams.noDataText = "数据加载中...";
      this.$axios
        .post(this.$url + "/ask_for_data", {
          page: this.tableParams.Tpage,
          choosedOptions: this.choosedOptions,
          askData: this.askData,
          userToken: userToken
        })
        .then(
          response => (
            (this.tableParams.contentData = response.data.res_record),
            (this.tableParams.totalItems = response.data.total_n),
            (this.tableParams.loading = false),
            (this.tableParams.noDataText = "暂无数据")
          )
        );
    },
    handlePage(value) {
      this.Tpage = value;
      this.get_data();
    },
    searchGo(value) {
      this.choosedOptions = value;
      this.get_data();
      this.goAnchor("#data-list");
      this.$emit("refreshDo", value);
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    }
  },

  computed: {
    
  },
  filters: {},
  created() {
    this.get_data();
  }
};
</script>
<style scoped>
.outer-wrapper {
  width: 1260px;
  margin: 0 auto;
}

.m-option-wrapper {
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.table-wrapper {
  margin-top: 10px;
}

.pane_label {
  font-size: 15px;
  font-weight: 800;
}
</style>