<template>
  <div>
    <div v-for="catagory in optionsData.catagoried" :key="catagory.title">
      <div class="catagory-option-wrapper" v-if="catagory.content.length!==0">
        <div class="option-title-wrapper">
          <span class="title-box">{{catagory.title}}</span>
        </div>
        <choice-box
          v-for="element in catagory.content"
          :selist="choosedOptions.selectedList"
          :main_title="element.option_title"
          :choices="element.value_list"
          :key="element.value_list.id"
          :key_name="element.key_name"
          @click_f="click_fu"
        ></choice-box>
      </div>
    </div>
    <choice-box
      v-for="element in optionsData.rest"
      :selist="choosedOptions.selectedList"
      :main_title="element.option_title"
      :choices="element.value_list"
      :key="element.value_list.id"
      :key_name="element.key_name"
      @click_f="click_fu"
    ></choice-box>
    <selected-box :selist="choosedOptions.selectedList" @minus_f="minus_fu"></selected-box>
    <div class="button-wrapper">
      <div class="keyword-input-wrapper">
        <label class="keyword-label">
          关键字：
          <Input v-model="choosedOptions.keyword" placeholder="请输入..." clearable class="keyword-input" />
        </label>
      </div>
      <Button class="main-button" type="primary" icon="ios-search" @click="go_search">开始筛选</Button>
      <Button class="main-button re-button" type="primary" @click="reset_choices">重置选项</Button>
    </div>
  </div>
</template>
<script>
import choiceBox from "./ChoiceBox.vue";
import selectedBox from "./SelectedBox.vue";

let userToken = localStorage.getItem("current_user_token");

export default {
  name: "optionPanel",
  components: {
    choiceBox,
    selectedBox
  },
  props: {
    askData: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      choosedOptions: {
        selectedList: [],
        keyword: ""
      },
      optionsData: {
        catagoried:[
          { title: "线路", content: [] },
          { title: "风险点特征", content: [] },
          { title: "风险状态跟踪", content: [] }
        ],
        rest:[]
      }
    };
  },
  methods: {
    get_option_list() {
      this.$axios
        .post(this.$url + "/ask_list_option", {
          askData: this.askData,
          userToken: userToken
        })
        .then(response => {
      for (let ele of response.data) {
        if (ele.catagory === "cityAndLine") {
          this.optionsData.catagoried[0].content.push(ele);
        } else if (ele.catagory === "riskClass") {
          this.optionsData.catagoried[1].content.push(ele);
        } else if (ele.catagory === "correctStat") {
          this.optionsData.catagoried[2].content.push(ele);
        } else {
          this.optionsData.rest.push(ele);
        }
      }
        });
    },
    click_fu(value) {
      let tmp_key = this.choosedOptions.selectedList.indexOfObject(value);
      if (tmp_key == -1) {
        this.choosedOptions.selectedList.push(value);
      } else {
        this.choosedOptions.selectedList.splice(tmp_key, 1);
      }
    },
    minus_fu(value) {
      let tmp_key = this.choosedOptions.selectedList.indexOfObject(value);
      this.choosedOptions.selectedList.splice(tmp_key, 1);
    },
    reset_choices() {
      this.choosedOptions.selectedList.splice(0, this.choosedOptions.selectedList.length);
      this.$emit("searchGo", this.choosedOptions);
    },
    go_search() {
      this.$emit("searchGo", this.choosedOptions);
    }
  },

  computed: {
  },
  filters: {},
  created() {
    this.get_option_list();
  }
};
</script>
<style scoped>
.button-wrapper {
  text-align: right;
  height: 50px;
}

.main-button {
  margin-right: 30px;
}

.main-button {
  background-color: #993333;
  color: #ddd;
  border: none;
}

.main-button:hover {
  background-color: #993333;
  border: none;
  color: #fff;
}

.re-button {
  margin-right: 150px;
}

.table-wrapper {
  margin-top: 10px;
}

.echarts-wrapper {
  margin: 0 auto;
}

.keyword-input-wrapper {
  float: left;
  margin-left: 20px;
}

.keyword-label {
  color: #999;
  font-size: 14px;
}

.keyword-input {
  width: 400px;
  margin-left: 20px;
}

.catagory-option-wrapper {
  border: 2px dashed #b4a078;
  margin: 10px 20px;
  padding: 10px 0;
  overflow: hidden;
}

.option-title-wrapper {
  color: #006699;
  font-size: 14px;
  font-family: SimHei;
  text-align: center;
  padding-top: 10px;
  font-weight: 700;
}

.title-box {
  border-left: 1px solid #006699;
  border-right: 1px solid #006699;
  padding: 0 15px;
}
</style>